<script>
    import Header from '@/template/header.svelte';
    import Footer from '@/template/footer.svelte';
    import SvelteMarkdown from 'svelte-markdown';

    const complaintsMarkdown = `

# Content Provider Agreement

This Content Provider Agreement ("Agreement") is entered into by and between BuyMyNudes ("Platform"), maintained by Bilin GmbH ("Company"), and the individual or entity identified below ("Content Provider"). This Agreement governs the terms and conditions under which the Content Provider may submit, upload, and distribute content through the Platform.

## 1. Definitions

- "Content" refers to any material, including but not limited to images, videos, text, and audio, that the Content Provider uploads to the Platform.
- "User" refers to any individual who accesses or uses the Platform.

## 2. Content Ownership and License

2.1 The Content Provider retains all ownership rights to the Content they upload to the Platform.

2.2 By uploading Content to the Platform, the Content Provider grants the Platform a worldwide, non-exclusive, royalty-free license to use, reproduce, distribute, and display the Content for the purpose of operating and promoting the Platform.

## 3. Prohibited Activities

The Content Provider agrees not to engage in any activity that is illegal or otherwise violates the Platform's Standards. This includes but is not limited to:

3.1 Uploading content that infringes on the intellectual property rights of others.

3.2 Distributing content that is harmful, abusive, defamatory, or invasive of privacy.

3.3 Engaging in activities that exploit or harm minors in any way.

3.4 Uploading any illegal content, including but not limited to child pornography, bestiality, and content involving non-consensual activities.

3.5 Impersonating another person or entity, or falsely stating or misrepresenting their affiliation with a person or entity.

3.6 Using the Platform to transmit any material that contains viruses, trojan horses, or other harmful computer programming.

## 4. Consent and Release

4.1 The Content Provider must obtain and keep on record written consent from all persons depicted in the Content. This consent must cover the following areas:

a) Consent to be depicted in the Content.
b) Consent to allow for the public distribution of the Content and to upload the Content to the Platform.
c) If the Content will be made available for downloading by other users, consent to have the Content downloaded.

4.2 The Content Provider must provide copies of these consent forms to the Platform upon request.

## 5. Identity and Age Verification

5.1 The Content Provider is required to verify the identity and age of all persons depicted in the Content to ensure that all persons depicted are adults (18 years of age or older).

5.2 The Content Provider must be able to provide supporting documents upon request. This includes but is not limited to:

a) Government-issued identification documents.
b) Other forms of age verification as required by law or the Platform.

5.3 The Content Provider agrees to cooperate fully with any additional verification processes the Platform may implement in the future.

## 6. User Download Consent

6.1 The Content Provider acknowledges and agrees that Users may download their Content if the Content Provider has opted in to allow such downloads.

6.2 The Content Provider must obtain explicit consent from all persons depicted in the Content for this purpose.

6.3 The Content Provider understands that once Content is downloaded by a User, the Platform cannot control its further distribution or use.

## 7. AI and Face-Matching Verification

7.1 The Platform will perform face-matching verification of the uploaded Content with the face data from the Content Provider's identity verification to ensure authenticity.

7.2 The Platform may also apply additional AI models for body detection and child pornography detection.

7.3 By signing this Agreement, the Content Provider consents to these verification processes and agrees to cooperate with any additional verification measures the Platform may implement.

## 8. Content Moderation and Removal

8.1 The Platform reserves the right to moderate, edit, or remove any Content that violates this Agreement or the Platform's Standards.

8.2 The Content Provider acknowledges that the Platform may be required by law to report certain types of illegal Content to relevant authorities.

## 9. Revenue Sharing and Payments

9.1 The Platform will share revenue with the Content Provider according to the rates and terms specified in the separate Revenue Sharing Agreement.

9.2 Payments will be made to the Content Provider on a monthly basis, provided the account balance meets the minimum payout threshold.

9.3 The Content Provider is responsible for all taxes related to the income received from the Platform.

## 10. Indemnification

The Content Provider agrees to indemnify, defend, and hold harmless the Platform and the Company from any claims, liabilities, damages, and expenses (including reasonable attorneys' fees) arising out of or related to the Content uploaded by the Content Provider or any breach of this Agreement.

## 11. Limitation of Liability

11.1 The Platform shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues.

11.2 The total liability of the Platform for any claim arising out of or relating to this Agreement shall not exceed the amount paid by the Platform to the Content Provider during the 12 months preceding the claim.

## 12. Term and Termination

12.1 This Agreement will commence on the date it is accepted by the Content Provider and will continue until terminated by either party.

12.2 The Platform reserves the right to terminate this Agreement and the Content Provider's access to the Platform at any time for any reason, with or without notice.

12.3 Upon termination, the Content Provider's right to upload new Content will cease immediately, but previously uploaded Content may remain on the Platform as per the license granted in Section 2.2.

## 13. Dispute Resolution

13.1 Any dispute arising out of or relating to this Agreement shall first be attempted to be resolved through good faith negotiations between the parties.

13.2 If the dispute cannot be resolved through negotiation, it shall be submitted to binding arbitration in accordance with the rules of the German Arbitration Institute (DIS).

## 14. Miscellaneous

14.1 Governing Law: This Agreement shall be governed by and construed in accordance with the laws of Germany, without regard to its conflict of law principles.

14.2 Entire Agreement: This Agreement constitutes the entire agreement between the parties with respect to its subject matter and supersedes all prior agreements and understandings.

14.3 Amendments: This Agreement may only be amended by a written agreement signed by both parties.

14.4 Severability: If any provision of this Agreement is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that this Agreement will otherwise remain in full force and effect and enforceable.

14.5 Assignment: The Content Provider may not assign or transfer this Agreement without the prior written consent of the Platform. The Platform may freely assign this Agreement.

14.6 Notices: All notices under this Agreement shall be in writing and shall be deemed to have been duly given when received, if personally delivered; when receipt is electronically confirmed, if transmitted by email; and upon receipt, if sent by certified or registered mail, return receipt requested.

By submitting Content to the Platform, the Content Provider acknowledges that they have read, understood, and agree to be bound by the terms of this Agreement.`;
</script>

<Header />
<main class="prose mx-auto flex max-w-7xl flex-col px-4 py-8 text-black dark:text-white sm:px-6 lg:px-8">
    <SvelteMarkdown source="{complaintsMarkdown}" />
</main>
<Footer />
